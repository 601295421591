var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{attrs:{"dark":""}},[_c('v-card',{staticClass:"text-center markdown-body pb-4",staticStyle:{"background":"#0d4474"},attrs:{"flat":"","tile":"","width":"100%"}},[_c('v-container',{staticClass:"mt-0",attrs:{"fill-height":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center mt-2",staticStyle:{"color":"white"},attrs:{"cols":"12","md":"12"}},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"hover",staticStyle:{"border":"0px solid #ddd","display":"inline"},attrs:{"src":"/icjia-logo.png","alt":"Footer logo","width":"100"},on:{"click":function($event){_vm.$router.push('/').catch(function (err) {
                      _vm.$vuetify.goTo(0);
                    })}}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Illinois Criminal Justice Information Authority")])])],1),_c('div',{staticClass:"pb-6"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","target":"_blank","aria-label":"Link to ICJIA on Facebook","href":"https://www.facebook.com/ICJIA/"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fab fa-facebook ")])],1)]}}])},[_c('span',[_vm._v("ICJIA on Facebook")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","target":"_blank","aria-label":"Link to ICJIA on YouTube","href":"https://www.youtube.com/c/illinoiscriminaljusticeinformationauthority"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fab fa-youtube ")])],1)]}}])},[_c('span',[_vm._v("ICJIA on YouTube")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","target":"_blank","href":"https://www.instagram.com/icjia_illinois/","aria-label":"ICJIA on Instagram"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa fa-instagram")])],1)]}}])},[_c('span',[_vm._v("ICJIA on Instagram")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","target":"_blank","href":"https://www.linkedin.com/company/icjia/","aria-label":"ICJIA on LinkedIn "}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa fa-linkedin")])],1)]}}])},[_c('span',[_vm._v("ICJIA on LinkedIn")])])],1),_c('div',{staticStyle:{"font-size":"12px","font-weight":"400"}},[_c('router-link',{staticStyle:{"color":"#fff","background":"none"},attrs:{"to":"/about/"}},[_vm._v("About")]),_vm._v(" | "),_c('router-link',{staticStyle:{"color":"#fff","background":"none"},attrs:{"to":"/about/contact/"}},[_vm._v("Contact")]),_vm._v(" | "),_c('a',{staticStyle:{"color":"#fff","background":"none"},attrs:{"href":"https://archive.icjia.cloud","target":"_blank"}},[_vm._v("Document Archive")]),_vm._v(" | "),_c('router-link',{staticStyle:{"color":"#fff","background":"none"},attrs:{"to":"/foia/"}},[_vm._v(" FOIA")]),_vm._v(" | "),_c('router-link',{staticStyle:{"color":"#fff","background":"none"},attrs:{"to":"/forms/lap-request/"}},[_vm._v(" Language Access Request")]),_vm._v(" | "),_c('router-link',{staticStyle:{"color":"#fff","background":"none"},attrs:{"to":"/about/privacy/"}},[_vm._v("Privacy")]),_vm._v(" | "),_c('router-link',{staticStyle:{"color":"#fff","background":"none"},attrs:{"to":"/forms/grant-status/"}},[_vm._v("Grant Status Request")]),_vm._v(" | "),_c('a',{staticStyle:{"color":"#fff","background":"none"},attrs:{"href":"https://visitor.r20.constantcontact.com/manage/optin?v=001MqUcqqvjwLCJXlLMSWbTe3zHHmEQgFeBuHvBcJWTbwgrxFbDSGx4HSUPpI6DJWMUPgbljtLxffqIcGFTgCnr-auak88ybvRxpoJlTMGPtZs%3D","target":"_blank"}},[_vm._v("Subscribe to CJ Dispatch")])],1),_c('div',{staticClass:"mt-3",staticStyle:{"font-size":"12px","font-weight":"300","color":"#ccc"}},[_c('strong',[_vm._v("© "+_vm._s(new Date().getFullYear())+" Illinois Criminal Justice Information Authority")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }